import CheckoutFreeDelivery from '~/themes/base/components/cart/CheckoutFreeDelivery'

export default {
  extends: CheckoutFreeDelivery,
  data() {
    return {
      shipperDiscounts: [],
    }
  },
  async created() {
    try {
      this.shipperDiscounts = (await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/cart/shipper-discounts`)).data
    } catch (e) {
      // Do nothing
    }
  },
  computed: {
    textFreeDelivery() {
      if (this.shipperDiscounts && this.shipperDiscounts.length > 1) {
        const limits = this.shipperDiscounts.map(discount => discount.itemsPriceLimit)
        const maxLimit = Math.max(...limits)
        if (this.$store.state.cart.cartData.prices.totalWithTax > maxLimit) {
          return this.$t('CheckoutFreeDelivery.active')
        } else {
          return this.$t('CheckoutFreeDelivery.activePartial')
        }
      }
      return this.$t('CheckoutFreeDelivery.active')
    },
  },
}
